<template>
    <div>
<SubHeader :link1="{ name: 'Comments'}" :link1Name="$t('Comments')" :linkActiveName="$t('View')" :linkB1="{ name: 'Comments'}" :linkB1Name="$t('Back')" 
/>
<DoneAlert :msg="$t('Data has been updated')" :visible="ShowDoneAlert" />
 <ErrorAlert :msg="errorMgs" :visible="ShowError" />
 <b-container  class=" mt-5" >
<FormSkeleton  v-if="!showForm" />

<b-card bg-variant="light" v-if="showForm" :header="$t('Edit')+ ' ' + $t('User') " class="text-left">
<br>   
<b-form @submit="onSubmit"  v-if="show"  enctype="multipart/form-data">
<b-row>
    <b-col cols="12"  > 
    <div>
    <b-alert :show="ShowError" dismissible variant="danger">
      
    {{errorMgs}}

    </b-alert>
     </div>
     </b-col>
</b-row>
  <b-row v-if="previewImage" class="mb-3 pb-5 border-bottom">
          <b-col cols="6" > 
            <h5 >{{$t('image')}}</h5>
          <b-img :src="previewImage" fluid></b-img>
             </b-col>
        </b-row>
<b-row align-h="center">
   <!--image-->
    
<b-col cols="12" lg="6" xl="6" > 

    
         <!-- input -->
      <b-form-group  :label="$t('Name')" label-for="input-1" :class="{ 'form-group--error': $v.form.name.$error }">
        <b-form-input  v-model.trim="form.name" @input="setTouched('name')" type="text" required ></b-form-input>
         <b-form-invalid-feedback  v-if="$v.form.name.$error"  :state="$v.form.name.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
  
        <!-- input -->
      <b-form-group :label="$t('Email')" label-for="input-1" :class="{ 'form-group--error': $v.form.email.$error }">
        <b-form-input   v-model.trim="form.email" @input="setTouched('email')" type="email" required ></b-form-input>
         <b-form-invalid-feedback v-if="$v.form.email.$error" :state="$v.form.email.required"> {{$t('required_form')}} </b-form-invalid-feedback>
         <b-form-invalid-feedback v-if="$v.form.email.$error" :state="$v.form.email.email"> {{$t('email_form')}} </b-form-invalid-feedback>
         <div v-if="errorsBack.email" >
            <b-form-invalid-feedback v-for="error in errorsBack.email" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
      </b-form-group>
     
  
      <!-- input -->
      <b-form-group  :label="$t('Subject')" label-for="input-1" :class="{ 'form-group--error': $v.form.subject.$error }">
        <b-form-input  v-model.trim="form.subject" @input="setTouched('subject')" type="text"  ></b-form-input>
         <b-form-invalid-feedback  v-if="$v.form.subject.$error"  :state="$v.form.phone.numeric"> {{$t('required_form')}} </b-form-invalid-feedback>
           <div v-if="errorsBack.phone" >
            <b-form-invalid-feedback v-for="error in errorsBack.subject" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
      </b-form-group>
    
   
      
   </b-col>

   <b-col cols="12" lg="6" xl="6" > 
  <!-- input -->
      <b-form-group  :label="$t('phone')" label-for="input-1" :class="{ 'form-group--error': $v.form.phone.$error }">
        <b-form-input  v-model.trim="form.phone" @input="setTouched('phone')" type="text"  ></b-form-input>
         <b-form-invalid-feedback  v-if="$v.form.phone.$error"  :state="$v.form.phone.numeric"> {{$t('phone_form')}} </b-form-invalid-feedback>
           <div v-if="errorsBack.phone" >
            <b-form-invalid-feedback v-for="error in errorsBack.phone" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
      </b-form-group>
      
     
    
      <b-form-group  :label="$t('Status')" label-for="input-3" :class="{ 'form-group--error': $v.form.status.$error }">
        <b-form-select  v-model="form.status" :options="statuses" @input="setTouched('status')" required ></b-form-select>
        <b-form-invalid-feedback v-if="$v.form.status.$error" :state="$v.form.status.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
   </b-col>
   </b-row>
   <b-row>
    <b-col cols="12"  >

         <!-- input -->
      <b-form-group  :label="$t('Comment')" label-for="input-1" :class="{ 'form-group--error': $v.form.comment.$error }">
        <b-form-textarea   v-model.trim="form.comment" @input="setTouched('Comment')" type="text" required rows="3"
      max-rows="6" ></b-form-textarea>
         <b-form-invalid-feedback v-if="$v.form.comment.$error" :state="$v.form.comment.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
        <!-- input -->
    </b-col>
</b-row>
<b-row>
    <b-col cols="12"  >
    <b-button type="submit" variant="info"><b-spinner v-if="isSendingForm" small></b-spinner>  {{$t('Save')}}</b-button>
    <b-button class="ml-3 mr-3" type="button" variant="danger" @click="deleteItem(main_id)"><b-spinner v-if="isSendingForm"  small></b-spinner>  {{$t('Delete')}}</b-button>
     </b-col>
</b-row>






    </b-form>
     </b-card>
</b-container>

</div>




</template>

<script>
import SubHeader from '@/components/layout/SubHeader.vue'
import axios from 'axios'
import { required, email,numeric } from 'vuelidate/lib/validators'
import DoneAlert from '@/components/alerts/DoneAlert.vue'
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
import FormSkeleton from '@/components/skeletons/FormSkeleton.vue'
export default {
        data() {
      return {
        form: {
            name: null,
            email: null,
            phone:null,
            subject:null,
            Comment:null,
            status:null,
            lang_id:this.$i18n.locale,
           

        },
        main_id:null,
        previewImage:"",
        statuses: [ 'read', 'unread'],
        roles:[],
        errorsBack:{},
        errorMgs:'',
        ShowError: false,
        show: true,
        ShowDoneAlert:false,
        isSendingForm:false,
        showForm:false,
      }
    },
    components:{
        SubHeader, DoneAlert,FormSkeleton,ErrorAlert
        
    },
     validations: {
      form:{
        phone:{numeric},
        subject : {  required },
        status: {  required },
        name: {  required },
        Comment: { required },
        email: { required,email},
    
    }
  },
  /***************** */
      methods: {
      fillForm(Thedata){
        this.form.name = Thedata.name;
        this.form.subject = Thedata.subject;
        this.form.email = Thedata.email;
        this.form.phone = Thedata.phone;
        this.form.status = Thedata.status;
        this.form.comment = Thedata.comment;
    
      
        this.main_id= Thedata.id;

    //console.log("zxcxz-----------"+ this.form);


      },

     setTouched(theModel) {
       if(theModel == 'phone' || theModel == 'all' ){this.$v.form.phone.$touch()}
       if(theModel == 'subject' || theModel == 'all' ){this.$v.form.subject.$touch()}
       if(theModel == 'status' || theModel == 'all' ){this.$v.form.status.$touch()}
       if(theModel == 'name' || theModel == 'all' ){this.$v.form.name.$touch()}
       if(theModel == 'Comment' || theModel == 'all' ){this.$v.form.comment.$touch()}
       if(theModel == 'email' || theModel == 'all' ){this.$v.form.email.$touch()}
      
    },
        fixingOption(json){
          var array=[];
          for (let index = 0; index < json.length; index++) {
            array[index] ={"text":this.$t(json[index].name),"value":json[index].name} ;
            
          }
           return array;
        },
      getCreateData(){
      axios.get(this.$store.state.backendUrl+'api/comment/'+this.$route.params.id+'/edit' , {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                if(response.Error){this.ShowError=true;this.errorMgs = this.$t('error_login');}
                else{
          
                  this.fillForm(response.data.success.comment);
                  this.showForm=true;
               //  console.log(response.data.success);
                }
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } , 
      onSubmit(event) {
        event.preventDefault()
        if (!this.$v.$invalid) {

        this.isSendingForm = true;
        //alert(JSON.stringify(this.form))
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
             if(value != null){
               formData.append(key,value)
               console.log(key, value);
              }
        }
      
        axios.post(this.$store.state.backendUrl+'api/comment/'+this.main_id+'?_method=PUT',formData, {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
               
                  //console.log(response.data.success);
                  this.isSendingForm = false;
                  this.ShowError=false;
                  this.errorMgs = "";
                  this.ShowDoneAlert = true;
                  setTimeout(()=>{     
                  this.ShowDoneAlert = false;
                  }, 3000);
                  this.getCreateData();
            })
            .catch( (error) => {
                console.log(error.response);
                this.ShowError=true;
                this.errorMgs = error;
                this.isSendingForm = false;
                 if(error){
                    this.errorsBack = error.response.data.errors;
                     console.log(error.response.data.errors);
                  }
            });
           }
      },
     
      ResetForm() {
        
        // Reset our form values
       
        this.form.name = null,
        this.form.subject = null,
         this.form.email = null,
        this.form.phone = null,
        this.form.comment = null,
        this.form.status = null,
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
      deleteItem($id){
          var r = confirm(this.$t('Are you sure?'));
            if (r == true) {
           axios.post(this.$store.state.backendUrl+'api/comment/'+$id+'?_method=DELETE',{_method:"DELETE"} , {
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
               
                  //console.log(response.data.success);
                  this.isSendingForm = false;
                  this.ShowError=false;
                  this.errorMgs = "";
                  this.ShowDoneAlert = true;
                  setTimeout(()=>{     
                  this.ShowDoneAlert = false;
                  }, 3000);
                  this.$router.push('/Comments')
            })
            .catch( (error) => {
                console.log(error.response);
                this.ShowError=true;
                this.errorMgs = error;
                this.isSendingForm = false;
                 if(error){
                    this.errorsBack = error.response.data.errors;
                     console.log(error.response.data.errors);
                  }
            });
            } 
      }

    },
    /********************* */
    mounted() {
      this.getCreateData();
      //this.form.lang_id = this.$i18n.locale;
    }
    
}
</script>